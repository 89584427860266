@import '../../assets/scss/base/variables';

.fbpTextGroup {
  &.fbpTextGroupLeft {
    display: flex;
    align-items: center;
    grid-gap: 12px;
  }
  .fbpTextLabel {
    display: flex;
  }
  .fbpTextLabelStar {
    color: var(--fbp-error-main) !important;
  }

  .fbpTextField {
    color: var(--fbp-text-secondary) !important;
    border: 1px solid var(--fbp-other-outlined-border) !important;
    border-radius: var(--fbp-border-radius-small);
    background-color: transparent;
    padding: 0 5px;
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    &:focus {
      outline: none;
      box-shadow: none !important;
      border-color: var(--fbp-other-outlined-border);
    }
    &.fbpTextFieldSmall {
      height: 36px !important;
    }
    &.fbpTextFieldMedium {
      height: 44px !important;
    }
    &.fbpTextFieldLarge {
      height: 52px !important;
    }
  }
}
