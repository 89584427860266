.labelAlignRight {
  display: flex;
  align-items: center;
  .ComponentLabel {
    justify-content: flex-end;
    padding-right: 12px;
    text-align: right;
    flex-shrink: 0;
    flex-basis: 177px;
    margin-bottom: 0;
  }
}
.labelAlignLeft {
  display: flex;
  align-items: center;

  .ComponentLabel {
    flex-shrink: 0;
    flex-basis: 177px;
    margin-bottom: 0;
  }
}
.fbpEmailComponentIcon {
  input.fbpEmailComponent {
    padding-left: 40px;
    &:focus {
      box-shadow: unset;
      border-color: var(--fbp-secondary-main);
    }
  }
}
.fbpEmailWrapper {
  flex: 1;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .commonIconInput {
    padding: 5px 12px;
    border: 1px solid var(--fbp-other-outlined-border);
    border-radius: 4px;
    height: 42px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    input {
      border: none;
      outline: none;
      height: 100%;
      width: 100%;
    }
    &:focus {
      border: 1px solid var(--fbp-secondary-main);
    }
  }
}
