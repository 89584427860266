.fbpFoldernameChip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 6px;
  background: var(--fbp-secondary-light);
  border-radius: 16px;
  transition: padding 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  width: max-content;
  min-height: 28px;
  &.colorScheme {
    padding: 3px 6px;
    background-color: #ffdad9;
    color: #ed0039;
    &:hover {
      padding-right: 25px;
    }
  }
  .checked {
    background: var(--fbp-primary-light);
  }
  .chipTrash {
    display: inline-flex;
    transition: all 0.3s;
    transform: translateX(20px);
    position: absolute;
    right: -20px;
  }
  &:hover {
    padding-right: 20px;
    .chipTrash {
      transform: translateX(0px);
      width: auto;
      opacity: 1;
      visibility: visible;
      right: 8px;
      cursor: pointer;
    }
  }
}
