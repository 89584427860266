#FormBuilderV3Module-container, .fbp-template-preview {
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
  input:not([type]),
  input[type='text']:not(.browser-default),
  input[type='password']:not(.browser-default),
  input[type='email']:not(.browser-default),
  input[type='url']:not(.browser-default),
  input[type='time']:not(.browser-default),
  input[type='date']:not(.browser-default),
  input[type='datetime']:not(.browser-default),
  input[type='datetime-local']:not(.browser-default),
  input[type='tel']:not(.browser-default),
  input[type='number']:not(.browser-default),
  input[type='search']:not(.browser-default),
  textarea.materialize-textarea {
    margin: 0;
    &:focus,
    &:active {
      box-shadow: unset !important;
      --webkit-box-shadow: unset;
      border-bottom: unset;
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: button;
  }
  .fbp_form_builder_footer {
    input[type="checkbox"] + span:not(.lever)::before,
    input[type="checkbox"]:not(.filled-in) + span:not(.lever)::after {
      content: none !important;
    }
  }
}
