@import 'Theming/Theming';

.fbpFormBuilderBodyWrapper {
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  .fbpFormBuilderBodyInner {
    height: 100vh;
    .fbpFormBuilderScrollable {
      height: 100%;
      background-color: $pageColor;
      background-image: $pageImage;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: initial;
      background-origin: initial;
      background-clip: initial;
      .publicPageContentWrp {
        position: relative;
        min-height: 100%;
        &::before {
          content: "";
          background-color: $pageImageColor;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: $pageImageOpacity;
        }
      }

      &.cardViewBodyWrapper {
        background-image: none;
        background-color: #f4f5f7;
      }
    }
  }
}
.fbpFormBuilderContents {
  overflow: hidden;
  height: 100%;
}
.templatePreview {
  padding: 20px;
  border-radius: 7px;
  background-color: #c8c7c70a;
  box-shadow: inset 1px 1px 9px 0 #c8c7c7;
  margin-bottom: 30px;
  .loaderWrapper {
    width: 100%;
    height: 78vh;
    display: grid;
    place-items: center;
  }
  .formTemplatePreview {
    height: 100%;
  }
}

.fbpFormBuilderPreviewWrapper {
  overflow-x: hidden;
  &:global(.fbpTabView) {
    .fbpFormBuilderBodyInner {
      margin: 0 auto;
      padding: 25px;
      width: 768px !important;
      height: 100% !important;
      transform: scale(0.78);
      transform-origin: center;
      z-index: 9;
      &::before {
        background-image: url('https://d24gujj67p1uc1.cloudfront.net/asset/images/forms/releases/1.0.0/tab-frame.png');
        width: 768px;
        height: 960px;
        position: fixed;
        left: 0;
        content: '';
        top: 0;
        z-index: -1;
        display: block;
      }
    }
    .fbpFormBuilderContents {
      height: 800px;
    }
    .fbpFormBuilderScrollable {
      .publicPageContentWrp {
        padding-top: 0;
      }
    }
  }

  &:global(.fbpPhoneView) {
    .fbpFormBuilderScrollable {
      border-radius: 32px;
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .publicPageContentWrp {
        padding-top: 0;
      }
    }
    .fbpFormBuilderContents {
      height: 700px;
      border-radius: 32px;
      section {
        padding: 20px;
      }
    }
    .fbpFormBuilderBodyInner {
      margin: 82px auto 0;
      padding: 13px 20px 14px;
      width: 360px;
      height: 726px;
      transform: scale(1);
      transform-origin: center;
      &::before {
        position: fixed;
        left: 0;
        content: '';
        top: 0;
        z-index: -1;
        display: block;
        background: url('https://d24gujj67p1uc1.cloudfront.net/asset/images/forms/releases/1.0.0/mobile-frame.png')
          no-repeat center center/ 360px 726px;
        width: 360px;
        height: 726px;
      }
    }
  }
}
:global(.fbp-template-preview) {
  .fbpFormBuilderBodyInner {
    height: 100%;
  }
}
