.center {
  margin: 0 auto;
}
.right {
  margin-left: auto;
}
.left {
  margin-right: auto;
}
.fullWidth {
  margin: 0;
  width: 100%;
  max-width: 100% !important;
}
.EmbedCardWrp {
  max-width: 100%;
}
:global(.fbpPhoneView) {
  .EmbedCardWrp {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .EmbedCardWrp {
    width: 100%;
  }
}
