.fbpBuildersElementsWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;
  gap: 4px;
  background: var(--fbp-text-primary-white);
  padding: 6px 8px;
  .fbpLeftEachElement {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--fbp-border-radius-small);
    flex-direction: column;
    flex: 0 1 calc(50% - 2px);
    background: var(--fbp-gray-50);
    transition: all 0.3s;
    cursor: pointer;
    gap: 4px;
    border: 1px solid transparent;
    &.disabled{
      opacity: 0.5;
      cursor: not-allowed;
      border: 0.5px solid var(--fbp-action-active);
      pointer-events: none;
    }
    svg {
      height: 15px;
      width: 15px;
    }
    &:hover {
      background: var(--fbp-secondary-states-outlined-hover-background);
      color: var(--fbp-text-secondary);
      border: 1px solid var(--fbp-action-active);
      border-radius: 4px;
      svg {
        path {
          fill: var(--fbp-action-active);
        }
      }
    }
  }
}

@media (max-width: 1120px) {
  .fbpBuildersElementsWrapper {
    .fbpLeftEachElement {
      font-size: 9px;
      text-align: center;
    }
  }
}
