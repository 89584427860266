@import '../Theming/Theming.scss';

.fbpPhoneWrapper {
  column-gap: 24px;
  display: flex;
}
.fbpPhoneWrapper,
.fbpPhoneWrapper {
  display: inline-flex;
  flex: 1 1 calc(50% - 24px);
}
.fbpCardBodyContent {
  margin: 20px auto;
  max-width: var(--fbp-theming-container-width);
  flex: 1 1;
  background: transparent;
  height: calc(100vh - 110px);
  .card-view-range-time .fbpPhoneWrapper {
    display: flex;
    column-gap: 24px;
  }
  @media (max-width: 1200px) {
    margin-left: 20px;
  }
}

//footer
.CardFooterWrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(67, 67, 67, 0.14);
  .cardPublicFooter {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    .CpFooterButton {
      border: none;
      padding: 9px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
    }
  }
}
