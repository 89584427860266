@import '../../assets/scss/base/variables';

.fbpLeftSidebar {
  border-radius: 8px;
  background-color: $fbp-primary-contrast;
  height: 100%;
  .fbpLeftSideTopbar {
    height: 56px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    padding: 0 16px;
    .fbpLeftSidebarTitle {
      transition-delay: 0.2s;
    }
    .fbpToggleSidebar {
      display: grid;
      place-items: center;
      border-radius: 8px;
      cursor: pointer;
      background-color: $fbp-primary-contrast;
    }
  }
  .sidebarListTitle {
    padding: 8px 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
    transition-delay: 0.3s;
    &.active {
      background: $fbp-action-selected;
    }
    &:hover {
      background: $fbp-action-selected;
    }
  }
  .CreatNewFolder {
    opacity: 1;
    transition-delay: 0.3s;
    .CreatNewFolderLi {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding-left: 10px;
      &:hover {
        background: $fbp-action-hover;
      }
    }
  }
  &.hideSidebar {
    background-color: transparent;
    .fbpLeftSideTopbar {
      width: 56px;
      border-radius: 8px;
      padding: 0;
      .fbpLeftSidebarTitle {
        width: 0;
        opacity: 0;
        pointer-events: none;
        transition-delay: 0s;
      }
      .fbpToggleSidebar {
        box-shadow: var(--fbp-elevation-6);
        width: 56px;
        height: 56px;
        transition: all 0.3s ease;
        transform-origin: center;
      }
    }
    .sidebarListTitle {
      opacity: 0;
    }
    .CreatNewFolder {
      opacity: 0;
    }
  }
}

.fbpLeftSide {
  width: 100%;
  background: $fbp-primary-contrast;
  border-radius: 4px;
  position: relative;
  max-width: 268px;
  transition: all 0.4s ease-in-out;
  .formSidebarList {
    border-radius: 4px;
    .moreIcon {
      width: 28px;
      height: 24px;
      margin-left: auto;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:hover {
      .moreIcon {
        opacity: 1;
      }
    }
  }
}
.fbpHideSidebar {
  .fbpLeftSide {
    max-width: 56px;
    width: 56px;
    background: transparent;
    .folderListsBottom {
      opacity: 0;
      pointer-events: none;
      width: 0;
    }
    .formSidebarList {
      visibility: hidden;
      width: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      opacity: 0;
    }
  }
}

.fbpMoreOptions {
  width: 28px;
  height: 24px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  li span {
    display: inline-flex;
  }
}

.fbpLastCreateFolder {
  max-height: 200px;
  padding-bottom: 0;
  li {
    &:hover {
      .fbpMoreOptions {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1300px) {
  .fbpLeftSide {
    max-width: 100%;
    .fbpLeftSidebar {
      display: block;
      position: fixed;
      top: 80px;
      z-index: 99;
      background: #fff;
      left: -100%;
      height: calc(100% - 80px);
      transition: all 0.5s ease;
    }
  }
  .showTabSidebar {
    .fbpLeftSidebar {
      max-width: 268px;
      width: 100%;
      left: 0;
      border-radius: 0;
      box-shadow: var(--fbp-elevation-6);
    }
  }
}
