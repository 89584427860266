.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 5px;
  .imageWrapper {
    height: 224px;
    width: 310px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .iconWrapper {
    width: auto;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    svg {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
