.labelAlignRight {
  display: flex;
  align-items: center;

  .ComponentLabel {
    justify-content: flex-end;
    padding-right: 12px;
    text-align: right;
    flex-shrink: 0;
    flex-basis: 177px;
    margin-bottom: 0;
  }
}

.labelAlignLeft {
  display: flex;
  align-items: center;

  .ComponentLabel {
    flex-shrink: 0;
    flex-basis: 177px;
    margin-bottom: 0;
  }
}

input.fbpEmailComponent {
  padding-left: 40px;

  &:focus {
    box-shadow: unset;
    border-color: var(--fbp-secondary-main);
  }
}

.fbpEmailWrapper {
  flex: 1;
  position: relative;
  width: 100%;
}
