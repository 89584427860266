.fbpBuilderSearchWrapper {
  border: 1px solid var(--fbp-other-outlined-border);
  border-radius: var(--fbp-border-radius-small);
  font-size: var(--pbp-fot);
  font-family: var(--poppins);
  font-weight: 400;
  background-color: var(--fbp-white-color);
  width: 100%;
  align-items: center;
  display: flex;
}
