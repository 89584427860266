.fullwidthAddress {
  flex: 1 0 calc(50% - 8px);
}
@media (max-width: 600px) {
  .fullwidthAddress {
    flex: 1 0 100%;
  }
}
:global(.fbpPhoneView) {
  .fullwidthAddress {
    flex: 1 0 100%;
  }
}
