.fbpImg {
  position: relative;
  padding: 0 60px;
  overflow: hidden;
}
//img {
//  background: #d9e2ff;
//  display: block;
//}
.fbpImgCtlOptions {
  background: #fff;
  padding: 12px 20px;
  column-gap: 40px;
  display: flex;
  align-items: center;
}

.fbp-adjust-img .fbpImgCtlOptions > div {
  width: 50%;
}

.fbp-adjust-img .fbpImgCtlOptions span {
  line-height: 0;
}

.fbpDragInfo {
  background: #d9e2ff;
  border-radius: 4px;
  border-color: #d9e2ff;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #002e75;
}
.pbf-col-gap {
  column-gap: 10px;
}

.fbpHeadingImageModalWrapper .fbp-img-flip svg rect:hover,
.fbpHeadingImageModalWrapper .fbp-img-rotate svg rect:hover {
  fill: var(--fbp-secondary-states-outlined-hover-background);
}
