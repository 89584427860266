@import 'Theming/Theming';

.body {
  height: calc(100vh - 52px);
  overflow-x: hidden;
  background-color: $pageColor;
  background-image: $pageImage;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  display: flex;
  position: relative;
  margin-top: 52px;
  &::before {
    content: "";
    background-color: $pageImageColor;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: $pageImageOpacity;
  }
  .showOnTab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    background: var(--fbp-background-paper);
    box-shadow: var(--fbp-elevation-6);
    width: 52px;
    height: 52px;
    position: absolute;
    top: 24px;
    left: 0;
    border-radius: 50%;
    cursor: pointer;
    transform: translateX(-100%);
    transition: all 0.3s;
    &.themeIcon {
      transform: translateX(100%);
      right: 0;
      left: auto;
    }
  }
  .bodyLeftSide {
    transform: translateX(0);
  }
  .bodyRightSide {
    height: 100%;
    transition: all 0.3s;
    transform: translateX(0);
  }
  &.hideLeftSide {
    .bodyLeftSide {
      transform: translateX(-100%);
    }
    .showOnTab {
      z-index: 1;
      &.plusIcon {
        left: 0;
        transform: translateX(10px);
      }
    }
  }

  &.hideRightSide {
    .bodyRightSide {
      transform: translateX(100%);
    }
    .showOnTab {
      &.themeIcon {
        transform: translateX(-10px);
        z-index: 1;
        left: auto;
        right: 0;
      }
    }
  }

  &.cardViewBody {
    background-image: none;
    background-color: #f4f5f7;
    &::before {
      content: none;
    }
  }
}

/* Body Content */
@media (max-width: 1450px) {
  .bodyRightSide {
    position: absolute;
    right: 0;
    z-index: 11;
  }
}
