.fbpBannerWrapper {
  .profileImageContainer {
    max-width: 1022px;
    margin: 0 auto;
    width: 120px;
    height: 120px;
    position: absolute;
    left: 60px;
    bottom: -60px;
    overflow: hidden;
    transition: all 0.3s ease;
    &.profileImageWithoutBanner{
      left: 25px;
      bottom: -15px;
      position: relative;
      margin: auto 0;
    }
    @media (max-width: 1024px) {
      width: 90px;
      height: 90px;
      bottom: -45px;
      left: 45px;
    }
    @media (max-width: 600px) {
      width: 60px;
      height: 60px;
      bottom: -30px;
      left: 15px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
  }
}
:global(.fbpTabView) {
  .bannerImage {
    height: 160px;
  }
  .profileImageContainer {
    width: 90px;
    height: 90px;
    bottom: -45px;
    left: 45px;
  }
}
:global(.fbpPhoneView) {
  .bannerImage {
    height: 100px;
  }
  .profileImageContainer {
    width: 60px;
    height: 60px;
    bottom: -30px ;
    left: 15px;
  }
}
