.fbpGlobalTimePicker {
    &.fbpGlobalTimePickerSmall {
    .MuiFormControl-root {
      input {
        height: 36px;
      }
    }
  }

  &.fbpGlobalTimePickerMedium {
    .MuiFormControl-root {
      input {
        height: 44px;
      }
    }
  }

  &.fbpGlobalTimePickerLarge {
    .MuiFormControl-root {
      input {
        height: 50px;
      }
    }
  }
}
