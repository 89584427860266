.fbpGlobalDatePicker {
  .MuiFormControl-root {
    padding: 0 !important;
    margin: 0;
    border-radius: var(--fbp-border-radius-small);
    cursor: pointer;

    input {
      margin: 0 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 22px !important;
      letter-spacing: 0.15px !important;
      color: var(--fbp-text-secondary);
    }

    .MuiInputBase-root.MuiInputBase-adornedEnd {
      padding-left: 12px;
    }
  }

  &.fbpGlobalDatePickerSmall {
    .MuiFormControl-root {
      input {
        height: 36px !important;
      }
    }
  }

  &.fbpGlobalDatePickerMedium {
    .MuiFormControl-root {
      input {
        height: 44px !important;
      }
    }
  }

  &.fbpGlobalDatePickerLarge {
    .MuiFormControl-root {
      input {
        height: 50px !important;
      }
    }
  }
}
