.fpbLoader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  background: rgba(28, 27, 31, 0.56);
  z-index: 9999;
  & > div {
    background: #ffffff;
    border-radius: 4px;
    padding: 24px;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
  }
}
