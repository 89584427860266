@import '../Theming/Theming.scss';

.fbpCardViewPreviewWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 52px 24px 24px;
  .fbpCardViewWrap {
    padding: 20px 0;
    max-width: 768px;
    width: 100%;
    margin: auto;
    .fbpCardViewContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
:global(.fbpTabView) {
  .fbpCardViewPreviewWrapper {
    width: 100%;
  }
}
:global(.fbpPhoneView) {
  .fbpCardViewPreviewWrapper {
    width: 100%;
    height: calc(100% - 92px);
    .fbpCardViewContent {
      height: 100%;
    }
  }
}
