@import '../../assets/scss/base/variables';

.fbpMoreDropdownWrapper {
  max-width: 100%;
  .fbpMoreDropdown {
    padding: 8px 0;
    border-radius: 0 !important;
    &:global(.fbpTwoColumns) {
      display: grid;
      grid-template-columns: 1fr 50%;
    }
    li {
      padding: 8px 16px;
      color: $fbp-text-primary;
      background-color: transparent;
      &:hover {
        background-color: $fbp-menu-hover;
      }
      &.active {
        background-color: $fbp-menu-hover;
      }
    }
    .fbpColorItems {
      grid-gap: 10px;
      gap: 10px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(2, 1fr);
      flex-wrap: wrap;
      padding: 8px 16px;
      cursor: pointer;
      .fbpColorItem {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: inline-flex;
        background: $fbp-primary-states-outlined-resting-border;
      }
    }

    &.fbpTwoColumns {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          flex: 0 1 50%;
          display: inline-flex;
        }
      }
    }
  }
}
