.fbpFormBuilderPages {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 8px;
  .pageNumberList {
    border-radius: 4px;
    background: var(--fbp-common-white);
    text-align: center;
    padding: 8px 0;
    cursor: pointer;
    transition: all 0.3s;
    &.active {
      background: var(--fbp-primary-states-outlined-hover-background);
      border: 1px solid #ccc;
    }
  }
  > div:hover {
    background: var(--fbp-primary-states-outlined-hover-background);
  }
}
