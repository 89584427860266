.nameWrapper {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  transition: all 0.3s cubic-bezier(0.47, 0.01, 0.4, 0.99);
}
@media (max-width: 600px) {
  .nameWrapper {
    flex-direction: column;
  }
}
:global(.fbpPhoneView) {
  .nameWrapper {
    flex-direction: column;
  }
}
