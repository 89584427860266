.main {
  .editPart {
    width: 60%;
    padding: 20px 0;
    .buttonStyleOption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 24px;
      border-bottom: 4px solid #f5f6f7;
      &.buttonStyle {
        display: block;
      }
    }
    .buttonStyles {
      .buttonStyleItem {
        cursor: pointer;
        svg {
          border-radius: 6px;
          > rect {
            stroke: none;
          }
          rect:first-child {
            fill: #f5f5f5;
          }
          rect:nth-child(2) {
            fill: none;
          }
          rect:last-child {
            stroke: #bdbdbd;
            fill: #bdbdbd;
          }
        }
        &.selected {
          svg {
            border: 2.5px solid #006df5;
            padding-top: 1px;
            rect:first-child {
              fill: #7fb5f9;
            }
            rect:last-child {
              stroke: #ffffff;
              fill: #ffffff;
            }
          }
        }
      }
    }
    .chooseColor {
      width: 84px;
      height: 40px;
      border: 1px solid rgba(148, 157, 178, 0.24);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      * {
        cursor: pointer;
      }
      .selectedColor {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        &.hasShadow {
          box-shadow: 0 0 1px 1px #999;
        }
      }
    }
  }
  .previewPart {
    width: 40%;
    max-width: 40%;
    padding: 35px 25px;
    background-color: var(--fbp-gray-300);
    .previewButton {
      width: 100%;
      height: 105px;
      background: var(--fbp-gray-A100);
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-top: 50%;
      button.outputButton {
        min-width: fit-content;
        min-height: 42px;
        padding: 8px 25px;
        justify-content: center;
      }
    }
  }
}
