.CustomIconPickerWrp {
  width: 200px;
  height: 120px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 6px;
  .iconItem {
    margin: 11px 3px;
    cursor: pointer;
    background: #fafafa;
    padding: 6px 3px 0px 6px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px #eee;
    &:hover {
      background: #dddddd;
    }
  }
}
