@import '../../assets/scss/base/variables';

.loadingContainer {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  .loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    p {
      font-size: 24px;
    }
    .circle {
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      background: conic-gradient($fbp-background-default 6deg, #006df5 354deg);
      padding: 5px;
      box-sizing: border-box;
      animation: 1s infinite forwards ease;
      animation-name: rotate;
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      .center {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: $fbp-background-default;
      }
    }
    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;
      gap: 10px;
      p {
        font-size: 14px;
      }
      .circle {
        min-width: 25px;
        height: 25px;
        padding: 3px;
      }
    }
  }
}
