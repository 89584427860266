@import 'Theming/Theming';

.fbpFormBuilderBodyContent {
  margin: 20px auto;
  max-width: $containerWidth;
  flex: 1;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: calc(100vh - 110px) !important;
  overflow-y: auto;
  position: relative;

  .fbpBuilderDeletePageButton {
    position: absolute;
    top: -52px;
    right: 4px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
  }
  & > div {
    position: relative;

    &.fbpClassicViewContent {
      background-image: $formImage;
      background-color: $formColor;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-attachment: $formImageAttachment;
      background-origin: initial;
      background-clip: initial;
      padding: 30px 0;

      &::before {
        content: "";
        background-color: $formImageColor;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: $formImageOpacity;
      }
    }
  }
  .fbpPageContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .fbpBuilderAddNew {
    position: absolute;
    left: 0;
    bottom: -42px;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
}

.fbpTabView {
  .fbpFormBuilderBodyContent {
    max-width: 708px;

    & > div {
      padding: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.fbpPhoneView {
  .fbpFormBuilderBodyContent {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 10px;

    & > div {
      padding-top: 0;
    }
  }
}

.fbpResultView {
  .fbpBuilderSettings {
    &.fbpBuilderAddNew {
      display: none;
    }
  }
}

.fbpCardViewPreviewPage {
  height: 100vh;
  overflow: hidden;
  background-color: $formColor;
  background-image: $formImage;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  position: relative;
  &::before {
    content: "";
    background-color: $formImageColor;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: $formImageOpacity;
  }
}
:global(.fbpPhoneView),:global(.fbpTabView){
  .fbpCardViewPreviewPage {
    height: 100%;
  }
}
