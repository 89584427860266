//builder animation
.BuilderAnimation {
  cursor: pointer;
  .fbpLayoutPositionSingleOption {
    color: var(--fbp-text-primary);
    .fbpLayoutPositionSingleOptionImg {
      border-radius: 4px;
      overflow: hidden;
      height: 70px;
      box-sizing: border-box;
      display: flex;
      align-items: baseline;
      justify-content: center;
      border: 1.5px solid var(--fbp-gray-200);
      transition: all 0.3s ease-in-out;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      color: var(--fbp-secondary-main);
      .fbpLayoutPositionSingleOptionImg {
        background-color: var(--fbp-outline-border-color);
        border: 1.5px solid var(--fbp-secondary-main);
      }
    }
    &.active {
      color: var(--fbp-secondary-main);
      .fbpLayoutPositionSingleOptionImg {
        background-color: var(--fbp-outline-border-color);
        border: 1.5px solid var(--fbp-secondary-main);
      }
    }
  }
}
//layout options
.BuilderLayoutOptions {
  .fbpLayoutPositionSingleOption {
    color: var(--fbp-text-primary);
    &:hover {
      color: var(--fbp-secondary-main);
      .fbpLayoutPositionSingleOptionImg {
        background-color: var(--fbp-outline-border-color);
        border: 1.5px solid var(--fbp-secondary-main);
      }
    }
    &.active {
      color: var(--fbp-secondary-main);
      .fbpLayoutPositionSingleOptionImg {
        background-color: var(--fbp-outline-border-color);
        border: 1.5px solid var(--fbp-secondary-main);
        img {
          filter: grayscale(0);
          &.cardLayoutImg {
            background-color: #f0f5fff7;
            border-radius: 4px;
          }
        }
      }
    }
    &:hover {
      & .cardLayoutImg {
        background-color: #f0f5fff7;
        border-radius: 4px;
      }
    }
  }
  .fbpLayoutSingleOptImg {
    width: 100%;
    height: 110px;
    transition: all 0.2s;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 6px;
    background-color: var(--fbp-gray-200);
    margin-bottom: 6px;
    border: 1.5px solid transparent;
    &.card {
      align-items: center;
    }
    img {
      filter: grayscale(1);
      &.cardLayoutImg {
        padding: 4px;
      }
    }
    &:hover {
      img {
        filter: grayscale(0);
      }
    }
  }
}

//builder theme
.BuilderLayoutTheme {
  .fbpSingleThemeOpt {
    padding: 4px;
    border-radius: 4px;
    width: calc(50% - 10px);
    border: 1.5px solid var(--fbp-gray-200);
    .themeImage {
      background-color: var(--fbp-gray-200);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .activeOverLay {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translateY(10px);
        transition: all 0.3s ease-in-out;

      }
    }
    &.active {
      border: 1.5px solid var(--fbp-outline-border-color);
      .themeImage {
        background-color: var(--fbp-outline-border-color);
        .activeOverLay {
          opacity: 1;
          pointer-events: all;
          backdrop-filter: blur(25px);
          transform: translateY(0);
          transition: all 0.3s ease-in-out;
        }
      }
    }
    &:hover {
      border: 1.5px solid var(--fbp-outline-border-color);
      .themeImage {
        background-color: var(--fbp-outline-border-color);
        .activeOverLay {
          opacity: 1;
          pointer-events: all;
          backdrop-filter: blur(25px);
          transform: translateY(0);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

//color scheme
.ColorScheme {
  .fbpColor {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: white;
    padding: 5px;
    border: 5px solid;
    box-sizing: border-box;
    cursor: pointer;
    svg {
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    &.active {
      svg {
        opacity: 1;
      }
    }
    &:hover svg {
      opacity: 1;
    }
  }
}

.colorSchemeAccordion {
  padding: 0;
}
