@import '../../../assets/scss/base/variables';

.fbpVideo {
  padding: 26px 32px;
  gap: 8px;
  width: 480px;
  height: 280px;
  border: 1px dashed $fbp-secondary-main;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  &.full {
    width: 100%;
  }
  video {
    object-fit: cover;
    border-radius: 8px;
  }
  .playVideo {
    position: relative;
    width: 100%;
    height: 100%;
    .videoDuration {
      position: absolute;
      right: 12px;
      top: 12px;
    }
    .playDuration {
      position: absolute;
      top: 12px;
      left: 12px;
    }
  }
}
.fbpCardMultimedia {
  .fbpVideo {
    border: 1px solid var(--fbp-other-outlined-border);
    height: 100%;
    padding: 0;
    min-height: 250px;
    width: 100%;
  }
  video {
    height: 100%;
  }
}
.center {
  margin: 0 auto;
}
.right {
  margin-left: auto;
}
.left {
  margin-right: auto;
}
.fullWidth {
  margin: 0;
  width: 100%;
  max-width: 100% !important;
}
