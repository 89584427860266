$base-font-family: 'Poppins';
$fbp-heading-image-width: 100%;
$fbp-heading-image-height: 240px;
$fbp-heading-image-border-radius: 12px;
$pbp-fot: 16px;
$fieldSpacing: 20px;
$innerGap: 80px;
$fbp-mmm: linear-gradient(180deg, #f4f4f4 6.87%, #ffffff 90.67%);
$fbp-border-radius-small: 4px;
$fbp-white-color: #fff;
$fbp-dark-blue: #3b3b98;
$fbp-outline-border-color: rgba(0, 109, 245, 0.5);
$fbp-text-primary: #1c1b1f;
$fbp-text-secondary: #546376;
$fbp-text-disabled: rgba(84, 99, 118, 0.66);
$fbp-text-secondary-white: rgba(255, 255, 255, 0.7);
$fbp-text-disabled-white: rgba(255, 255, 255, 0.2);
$fbp-text-primary-white: #ffffff;

$fbp-text-dark-primary: #ffffff;
$fbp-text-dark-secondary: rgba(255, 255, 255, 0.6);
$fbp-text-dark-disabled: rgba(255, 255, 255, 0.38);
$fbp-other-dark: #133159;

$fbp-primary-main: #6258ff;
$fbp-primary-dark: #15008a;
$fbp-primary-light: #d2ccff;
$fbp-primary-contrast: #ffffff;
$fbp-primary-states-outlined-resting-border: rgba(98, 88, 255, 0.5);
$fbp-primary-states-outlined-hover-background: rgba(98, 88, 255, 0.08);
$fbp-primary-states-contained-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  #6258ff;
$fbp-menu-hover: rgba(100, 56, 188, 0.04);

$fbp-secondary-main: #006df5;
$fbp-secondary-dark: #002e75;
$fbp-secondary-light: #d9e2ff;
$fbp-secondary-contrast: #ffffff;

$fbp-secondary-states-outlined-resting-border: rgba(0, 109, 245, 0.5);
$fbp-secondary-states-outlined-hover-background: rgba(0, 109, 245, 0.08);
$fbp-secondary-states-contained-hover: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
  #006df5;

$fbp-action-active: #949db2;
$fbp-action-hover: rgba(98, 88, 255, 0.06);
$fbp-secondary-hover: #dfedff;
$fbp-action-selected: rgba(28, 27, 31, 0.08);
$fbp-popup-mode-bg: rgba(28, 27, 31, 0.56);
$fbp-action-disabled: rgba(28, 27, 31, 0.38);
$fbp-action-disabled-background: rgba(28, 27, 31, 0.12);
$fbp-action-focus: rgba(28, 27, 31, 0.12);
$fbp-action-hover-white: rgba(255, 255, 255, 0.14);
$fbp-action-active-white: rgba(255, 255, 255, 0.08);
$fbp-action-active-black: rgba(84, 99, 118, 0.06);

$fbp-new-light: #ffdad9;
$fbp-error-main: #ed0039;
$fbp-error-dark: hsl(351, 100%, 29%);
$fbp-error-light: #ffdad9;
$fbp-error-contrast: #ed0039;
$fbp-error-states-outlined-resting-border: rgba(237, 0, 57, 0.5);
$fbp-error-states-outlined-hover-background: rgba(237, 0, 57, 0.08);
$fbp-error-states-contained-hover-background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
  #ed0039;
$fbp-error-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #ff264a;
$fbp-error-alert-background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  ),
  #ff264a;

$fbp-warning-main: #f2994a;
$fbp-warning-dark: #944200;
$fbp-warning-light: #ffdcc3;
$fbp-warning-contrast: #ffffff;
$fbp-warning-states-outlined-resting-border: rgba(242, 153, 74, 0.5);
$fbp-warning-states-outlined-hover-background: rgba(242, 153, 74, 0.08);
$fbp-warning-states-contained-hover-background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
  #f2994a;
$fbp-warning-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #f2994a;
$fbp-warning-alert-background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  ),
  #f2994a;

$fbp-info-main: #a352c2;
$fbp-info-dark: #5f0085;
$fbp-info-light: #f9d8ff;
$fbp-info-contrast: #ffffff;
$fbp-info-states-outlined-resting-border: rgba(163, 82, 194, 0.5);
$fbp-info-states-outlined-hover-background: rgba(163, 82, 194, 0.08);
$fbp-info-states-contained-hover-background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
  #a352c2;
$fbp-info-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #a352c2;
$fbp-info-alert-background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  ),
  #a352c2;

$fbp-success-main: #008945;
$fbp-success-dark: #006625;
$fbp-success-light: #baf0cb;
$fbp-success-contrast: #ffffff;
$fbp-success-states-outlined-resting-border: rgba(0, 137, 69, 0.5);
$fbp-success-states-outlined-hover-background: rgba(0, 137, 69, 0.1);
$fbp-success-states-contained-hover-background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3)
  ),
  #008945;
$fbp-success-alert-content: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #008945;
$fbp-success-alert-background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  ),
  #008945;

$fbp-background-default: #f4f5f7;
$fbp-background-paper: #ffffff;
$fbp-background-dark-default: #212b36;
$fbp-background-dark-paper: #161c24;
$fbp-border-radius: 8px;

$fbp-other-divider: rgba(148, 157, 178, 0.12);
$fbp-other-outlined-border: rgba(148, 157, 178, 0.24);
$fbp-other-backdrop-overlay: rgba(84, 99, 118, 0.5);
$fbp-other-filled-input-background: rgba(0, 0, 0, 0.09);
$fbp-other-input-line: rgba(84, 99, 118, 0.42);
$fbp-other-snackbar: #1c1b1f;
$fbp-other-rating-active: #ffb400;
$fbp-other-extra-linear: linear-gradient(80.05deg, #15008a 14.93%, #002e75 85.07%);
$fbp-dark: #133159;
$fbp-common-white: #fff;
$fbp-common-white-box-shadow: rgba(0, 0, 0, 0.08);
$fbp-common-black: #000;
$fbp-gray-50: #fafafa;
$fbp-gray-100: #f5f5f5;
$fbp-gray-200: #eeeeee;
$fbp-gray-300: #e0e0e0;
$fbp-gray-400: #bdbdbd;
$fbp-gray-500: #9e9e9e;
$fbp-gray-600: #757575;
$fbp-gray-700: #616161;
$fbp-gray-800: #424242;
$fbp-gray-900: #212121;
$fbp-gray-A100: #d5d5d5;
$fbp-gray-A200: #aaaaaa;
$fbp-gray-A400: #616161;
$fbp-gray-A700: #303030;

$fbp-outlined-border: 1px solid rgba(148, 157, 178, 0.12);
$fbp-outlined-border2: 1px solid rgba(148, 157, 178, 0.24);
$fbp-upper-outline: 0px -1px 0px rgba(28, 15, 19, 0.12);
$fbp-elevation-outlined: 0px 0px 0px 1px rgba(148, 157, 178, 0.1);
$fbp-elevation-1: 0px 1px 1px rgba(148, 157, 178, 0.14), 0px 1px 3px rgba(148, 157, 178, 0.12);
$fbp-elevation-2: 0px 3px 1px -2px rgba(148, 157, 178, 0.2), 0px 2px 2px rgba(148, 157, 178, 0.14),
  0px 1px 5px rgba(148, 157, 178, 0.12);
$fbp-elevation-3: 0px 3px 3px -2px rgba(148, 157, 178, 0.2), 0px 3px 4px rgba(148, 157, 178, 0.14),
  0px 1px 8px rgba(148, 157, 178, 0.12);
$fbp-elevation-4: 0px 2px 4px -1px rgba(148, 157, 178, 0.2), 0px 4px 5px rgba(148, 157, 178, 0.14),
  0px 1px 10px rgba(148, 157, 178, 0.12);
$fbp-elevation-5: 0px 3px 5px -1px rgba(148, 157, 178, 0.2), 0px 5px 8px rgba(148, 157, 178, 0.14),
  0px 1px 14px rgba(148, 157, 178, 0.12);
$fbp-elevation-6: 0px 3px 5px -1px rgba(148, 157, 178, 0.2), 0px 6px 10px rgba(148, 157, 178, 0.14),
  0px 1px 18px rgba(148, 157, 178, 0.12);
$fbp-elevation-7: 0px 4px 5px -2px rgba(148, 157, 178, 0.2),
  0px 7px 10px 1px rgba(148, 157, 178, 0.14), 0px 2px 16px 1px rgba(148, 157, 178, 0.12);
$fbp-elevation-8: 0px 5px 5px -3px rgba(148, 157, 178, 0.2),
  0px 8px 10px 1px rgba(148, 157, 178, 0.14), 0px 3px 14px 2px rgba(148, 157, 178, 0.12);
$fbp-elevation-9: 0px 5px 6px -3px rgba(148, 157, 178, 0.2),
  0px 9px 12px 1px rgba(148, 157, 178, 0.14), 0px 3px 16px 2px rgba(148, 157, 178, 0.12);
$fbp-elevation-10: 0px 6px 6px -3px rgba(148, 157, 178, 0.2),
  0px 10px 14px 1px rgba(148, 157, 178, 0.14), 0px 4px 18px 3px rgba(148, 157, 178, 0.12);

$fbp-yellow: rgba(255, 180, 0, 0.5);
$fbp-violet-500: rgba(215, 93, 68, 0.5);
$fbp-violet-300: rgba(81, 60, 134, 0.5);

$fbp-placeholder-color: #b4b8c6;

$fbp-global-font-size-body2: 14px;
$fbp-global-font-weight-body2: 600;
$fbp-global-line-height-body2: 18px;
$fbp-global-letter-spacing-body2: 0.5px;

$fbp-global-font-size-chip-medium: 13px;
$fbp-global-font-weight-chip-medium: 500;
$fbp-global-line-height-chip-medium: 18px;
$fbp-global-letter-spacing-chip-medium: 0.16px;

$fbp-global-font-size-badge-label: 12px;
$fbp-global-font-weight-badge-label: 500;
$fbp-global-line-height-cbadge-labe: 18px;
$fbp-global-letter-spacing-badge-label: 0.14px;

$fbp-global-font-size-subtitle1-semibold: 12px;
$fbp-global-font-weight-subtitle1-semibold: 500;
$fbp-global-line-height-subtitle1-semibold: 18px;
$fbp-global-letter-spacing-subtitle1-semibold: 0.14px;

$fbp-global-font-size-table-header: 14px;
$fbp-global-font-weight-table-header: 500;
$fbp-global-line-height-table-header: 24px;
$fbp-global-letter-spacing-table-header: 0.17px;

$fbp-global-font-size-helper-text: 12px;
$fbp-global-font-weight-helper-text: 400;
$fbp-global-line-height-helper-text: 14px;
$fbp-global-letter-spacing-helper-text: 0.4px;

$fbp-global-font-size-chip-regular: 13px;
$fbp-global-font-weight-chip-regular: 400;
$fbp-global-line-height-chip-regular: 18px;
$fbp-global-letter-spacing-chip-regular: 0.16px;

$fbp-global-font-size-chip-2: 11px;
$fbp-global-font-weight-chip-2: 500;
$fbp-global-line-height-chip-2: 16px;
$fbp-global-letter-spacing-chip-2: 0.16px;

$fbp-global-font-size-input-text: 16px;
$fbp-global-font-weight-input-text: 400;
$fbp-global-line-height-input-text: 24px;
$fbp-global-letter-spacing-input-text: 0.15px;
$white: #fff;
$delete-btn-color: #fb5629;
