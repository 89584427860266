@import '../../assets/scss/base/variables';

.fbpBody {
  display: flex;
  column-gap: 16px;
  height: calc(100vh - 182px);
  .templateItem {
    height: 300px;
    opacity: 1;
    transition-delay: 0.3s;
  }
  .AdminCatagoryWrp {
    opacity: 1;
    transition-delay: 0.3s;
  }
  .mainContent {
    max-width: calc(100% - 268px);
    padding: 16px;
    flex: 1;
    height: auto;
    background-color: $fbp-primary-contrast;
    border-radius: 4px;
  }
  .fbpLeftSide {
    width: 100%;
    background: $fbp-primary-contrast;
    border-radius: 4px;
    position: relative;
    max-width: 268px;
    transition: all 0.4s ease-in-out;
    .formSidebarList {
      border-radius: 4px;
      .moreIcon {
        width: 28px;
        height: 24px;
        margin-left: auto;
        opacity: 0;
        transition: opacity 0.3s;
      }
      &:hover {
        .moreIcon {
          opacity: 1;
        }
      }
    }
  }
  .fbpLeftSidebar {
    .fbpLeftSideTopbar {
      height: 56px;
      width: 100%;
      transition: all 0.3s ease-in-out;
      padding-left: 16px;
      .fbpLeftSidebarTitle {
        transition-delay: 0.2s;
        opacity: 1;
      }
      .fbpToggleSidebar {
        display: grid;
        place-items: center;
        border-radius: 8px;
        width: 56px;
        height: 100%;
        cursor: pointer;
        transition-delay: 0.2s;
      }
    }
  }
  &.hideSidebar {
    background-color: transparent;
    .mainContent {
      max-width: 100%;
    }
    .fbpLeftSide {
      background: transparent;
      width: 56px;
      .folderListsBottom {
        opacity: 0;
        pointer-events: none;
        width: 0;
      }
      .formSidebarList {
        visibility: hidden;
        width: 0;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
        opacity: 0;
      }
    }
    .fbpLeftSideTopbar {
      width: 56px;
      border-radius: 8px;
      padding: 0;
      .fbpLeftSidebarTitle {
        width: 0;
        opacity: 0;
        pointer-events: none;
        transition-delay: 0s;
      }
      .fbpToggleSidebar {
        box-shadow: var(--fbp-elevation-6);
        background-color: $fbp-primary-contrast;
      }
    }
  }
  &.showTabSidebar {
    flex-direction: column;
    gap: 16px;
    .fbpLeftSide {
      width: 100%;
      max-width: 100%;
    }
    .fbpToggleSidebar {
      height: auto;
    }
  }
}

@media (max-width: 1024px) {
  .fbpBody {
    flex-direction: column;
    gap: 15px;
    .mainContent {
      max-width: 100%;
    }
    .fbpLeftSide {
      max-width: 100%;
      width: 100%;

      .fbpLeftSidebar {
        display: block;
        position: fixed;
        top: 80px;
        z-index: 9999;
        background: #fff;
        left: -100%;
        height: calc(100% - 80px);
        transition: all 0.5s ease;
      }
    }
    &.showTabSidebar {
      .fbpLeftSidebar {
        left: 0;
        border-radius: 0;
        box-shadow: var(--fbp-elevation-6);
      }
    }
  }
}

.adminHeaderRowCell,
.adminBodyRowCell {
  flex: 1 1 24%;
  padding: 0 10px;
}
.adminHeaderRowCell:first-child,
.adminBodyRowCell:first-child {
  flex: 1 1 4%;
}
