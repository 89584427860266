.fbpGlobalCentralModal {
  background: white;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 6px 15px rgb(0 0 0 / 10%);
  left: unset;
  top: 2px;
  right: 0;
  position: fixed;
  overflow: hidden;
  width: 100%;
  &:focus{
    border: none;
  }
  &:global(.deleteWarningModal) {
    .centralModalHeader {
      padding: 6px;
    }
    .topModalHr {
      display: none;
    }
  }
  &.fbpGlobalCentralModalMedium {
    max-width: 700px;
    height: 84vh;
  }
  &.fbpGlobalCentralModalSmall {
    max-width: 500px;
  }
  .fbpGlobalCentralModalContainer {
    height: 100%;
    .fbpGlobalCentralModalBody {
      height: calc(100% - 165px);
      box-sizing: border-box;
      .fbpGlobalCentralModalFooter {
        display: flex;
        justify-content: space-between;
        padding: 32px 20px 20px 20px;
        align-items: center;
        grid-gap: 32px;
      }
    }
  }
}
