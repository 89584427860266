.fbpAudio {
  padding: 26px 32px;
  max-width: 480px;
  border-radius: 8px;
  background: var(--fbp-action-active-white);
  border: 1px dashed #006df5;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  audio {
    width: 100%;
  }
}
.center {
  margin: 0 auto;
}
.right {
  margin-left: auto;
}
.left {
  margin-right: auto;
}
.fullWidth {
  margin: 0;
  width: 100%;
  max-width: 100% !important;
}
