@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500&display=swap');
@import '../base/variables';
@import '../../../components/Builder/Theming/Theming';

.fbp__wrapper {
  background: var(--fbp-background-default);
  /* padding: 16px; */
}

.fbp-h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  color: var(--fbp-common-black);
}
.fbp-h2 {
  font-weight: 600;
  font-size: 42px;
  line-height: 56px;
  color: var(--fbp-common-black);
}
.fbp-h3 {
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  color: var(--fbp-common-black);
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
.fbp-h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: var(--fbp-common-black);
}
.fbp-h4-sb {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: var(--fbp-common-black);
}
.fbp-h4-sm {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: var(--fbp-common-black);
}
.fbp-h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: var(--fbp-text-primary);
}
.fbp-h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: var(--fbp-common-black);
}
.fbp-subtitle1-sm {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: var(--fbp-common-black);
}
.fbp-subtitle1-medium {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: var(--fbp-common-black);
}
.fbp-subtitle2 {
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--fbp-common-black);
}
.fbp-overline {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--fbp-common-black);
}
.fbp-caption {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: var(--fbp-common-black);
}
.fbp-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--fbp-text-secondary);
}
.fbp-body1 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--fbp-common-black);
}
.fbp-body-medium {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--fbp-common-black);
}
.fbp-body-sb {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--fbp-common-black);
}
.fbp-more-dropdown-wrapper .fbp-typo-body2-regular .MuiListItemText-root span.MuiTypography-root,
.fbp-typo-body2-regular .MuiTypography-root.MuiFormControlLabel-label,
.fbp-typo-body2-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--fbp-text-primary);
  font-family: 'Poppins';
}
.fbp-typo-body2-semibold {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--fbp-text-primary);
  font-family: 'Poppins';
}
.fbp-typo-body2-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--fbp-text-secondary);
  font-family: 'Poppins';
}
.fbp-add-folder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
}
.fbp-typo-body1-medium {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: var(--fbp-text-primary);
}
.fbp-typo-chip-light {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
}
.fbp-typo-chip-medium {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.16px;
}
.fbp-typo-button-small {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.46px;
  color: var(--fbp-text-secondary);
  cursor: pointer;
}
.fbp-typo-input-text {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--fbp-text-secondary);
}

.fbp-typo-button-large {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: var(--fbp-secondary-contrast);
}
.fbp-typo-preview-button {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: $fbp-text-primary-white;
}
.fbp-table-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.17px;
  color: var(--fbp-text-primary);
}

.fbp-helper-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: var(--fbp-text-secondary);
}

.fbp-tab-header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--fbp-other-snackbar);
}
.fbp-input-label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}
