.fbpTextAreaGroup {
  border: 1px solid var(--fbp-other-outlined-border);
  border-radius: var(--fbp-border-radius-small);
  padding: 5px 12px;
  .fbpTextAreaField {
    max-width: 100%;
    border: none;
    color: var(--fbp-text-secondary);
    font-size: var(--pbp-fot);
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  .fbpTextAreaLabel {
    display: flex;
    padding: 0 0 12px 0;

    .fbpTextAreaLabelStar {
      color: var(--fbp-error-main);
    }
  }

  .fbpTextAreaGroupLeft {
    display: flex;
    align-items: center;
    grid-gap: 12px;

    .fbpTextAreaLabel {
      padding: 0 !important;
      flex-shrink: 0;
    }
  }
}
