.drpOuterClick {
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1100;
}

.drpWrapper {
  position: relative;
  font-family: var(--poppins);
  .drpContentWrapper {
    width: 100%;
    background-color: #fff;
    border: 1px solid var(--fbp-other-divider);
    border-radius: 4px;
    height: 40px;
    cursor: pointer;
    min-width: 260px;
    &:focus {
      border-color: var(--fbp-secondary-main);
    }
    .drpContentArea {
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
      gap: 5px;
      padding-left: 7px;
    }
  }
  .drpBody {
    position: absolute;
    top: 42px;
    left: -49px;
    z-index: 1102;
    border: 1px solid var(--fbp-other-outlined-border);
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  button {
    &:focus {
      background-color: inherit !important;
    }
  }
}
