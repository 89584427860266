@import '../../../assets/scss/base/variables';

.addTagWrapper {
  padding: 10px;
  width: 260px;
  .addTagInputBox {
    width: 100%;
    height: 40px;
    border: $fbp-outlined-border;
    border-radius: 4px;
    & input {
      margin: 0 !important;
      width: 100%;
      height: 100%;
      border: none !important;
      box-shadow: none !important;
      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
  .addAllTagsWrp {
    display: flex;
    height: 120px;
    overflow: auto;
    padding: 8px 0;
    flex-direction: column;
    gap: 8px;
    .singleTag {
      width: 100%;
      display: flex;
      align-items: center;
      height: 28px;
      border-radius: 4px;
      cursor: pointer;
      .tagName {
        flex: 1;
        width: 100%;
        height: 28px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
      }
      .tagActionsIcon {
        display: inline-flex;
        margin: 0 1px;
      }
    }
  }
}
.tagAddDeleteBox {
  .tagAddDeleteItem {
    width: 116px !important;
    display: flex !important;
    align-items: center !important;
    padding: 10px !important;
    cursor: pointer;
    &:hover {
      background-color: $fbp-action-hover;
    }
  }
}
.tagListColorPicker {
  width: 246px;
  padding: 8px;
  .tagListColorsContainer {
    height: 110px;
    overflow: auto;
    &.fbp_has_scroll::-webkit-scrollbar {
      width: 2px !important;
    }
  }
  .tagListColorsWrp {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    .tlSingleColor {
      width: 30px;
      height: 20px;
      background-color: cadetblue;
      border-radius: 4px;
    }
  }
}
