.fbpGlobalModalWrapper {
  box-shadow: 0 6px 15px rgb(0 0 0 / 10%);
  left: unset;
  background: var(--fbp-white-color);
  top: 2px;
  bottom: 2px;
  right: 0;
  position: fixed;
  overflow: hidden;
  max-width: calc(100% - 32px);
  width: 100%;
  margin: 86px 16px 20px 16px;
  .fbpGlobalModalContainer {
    .fbpGlobalModalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px 0 20px;
      .fbpGlobalModalHeaderIcon {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: var(--fbp-other-divider);
        cursor: pointer;
      }
    }
  }
  &:focus {
    outline: none;
  }
}
