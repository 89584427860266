:global(.fbpPhoneView) {
  .fbpDefaultTyWr {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    .fbpDefaultTyLeft {
      flex: 1 1 100%;
    }
    .fbpDefaultTyRight {
      flex: 1 1 100%;
      width: 100%;
    }
  }
}
