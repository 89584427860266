/*=============================================
=               Condition Item                =
=============================================*/

.fbpConditionItem {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ece9e6;
  box-shadow: 0 2px 4px rgb(86 70 50 / 10%);
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  margin-left: 0 !important;
}

.fbpConditionItem:hover .fbpConditionActionItem {
  visibility: visible;
  opacity: 1;
  transition: 350ms opacity ease-in-out;
}

.fbpConditionAction {
  position: absolute;
  right: 0;
  display: flex;
  gap: 16px;
  margin-right: 20px;
  z-index: 999;

  &Item {
    visibility: hidden;
    opacity: 0;
    color: #fff;
    background-color: #0099ff;

    display: grid;
    place-content: center;

    padding: 5px;
    border-radius: 5px;

    cursor: pointer;
  }
}

.fbpConditionSettingBg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  backdrop-filter: blur(4px);
}

.fbpConditionSettingContent {
  width: 100%;
  height: 100%;
  position: absolute;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 2;

  &Item {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
    gap: 8px;
    border-radius: 12px;
    cursor: pointer;
  }
  &Item:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

/*=====  End of ConditionItem  ======*/
