:root {
  --fbp-theming-form-color: #ffffff;
  --fbp-theming-form-image: none;
  --fbp-theming-form-image-opacity: 0;
  --fbp-theming-form-image-color: #000;
  --fbp-theming-form-image-attachment: scroll;
  --fbp-theming-page-color: #f4f5f7;
  --fbp-theming-page-image: none;
  --fbp-theming-page-image-opacity: 0;
  --fbp-theming-page-image-color: #000;
  --fbp-theming-font-color: #1c1b1f;
  --fbp-theming-input-background: #ffffff;
  --fbp-theming-placeholder-color: #b4b8c6;
  --fbp-theming-text-color: #546376;
  --fbp-theming-container-width: 800px;
  --fbp-theming-field-spacing: 20px;
  --fbp-theming-inner-gap: 80px;
  --fbp-theming-font-family: 'Poppins', sans-serif;
  --fbp-theming-label-font-size: 16px;
  --fbp-theming-card-view-label-font-size: 36px;
  --fbp-theming-sub-text-color: #546376;
  --fbp-theming-label-sub-text-font-size: 12px;
  --fbp-theming-label-description-font-size: 12px;
}

$formColor: var(--fbp-theming-form-color);
$formImage: var(--fbp-theming-form-image);
$formImageOpacity: var(--fbp-theming-form-image-opacity);
$formImageColor: var(--fbp-theming-form-image-color);
$formImageAttachment: var(--fbp-theming-form-image-attachment);
$pageColor: var(--fbp-theming-page-color);
$pageImage: var(--fbp-theming-page-image);
$pageImageOpacity: var(--fbp-theming-page-image-opacity);
$pageImageColor: var(--fbp-theming-page-image-color);
$fontColor: var(--fbp-theming-font-color);
$inputBackground: var(--fbp-theming-input-background);
$subTextColor: var(--fbp-theming-sub-text-color);
$placeholderColor: var(--fbp-theming-placeholder-color);
$textColor: var(--fbp-theming-text-color);
$containerWidth: var(--fbp-theming-container-width);
$fieldSpacing: var(--fbp-theming-field-spacing);
$innerGap: var(--fbp-theming-inner-gap);
$fontFamily: var(--fbp-theming-font-family);
$labelFontSize: var(--fbp-theming-label-font-size);
$cardViewLabelFontSize: var(--fbp-theming-card-view-label-font-size);
$subTextFontSize: var(--fbp-theming-label-sub-text-font-size);
$descriptionFontSize: var(--fbp-theming-label-description-font-size);