.ImageWrapper {
  display: flex;
  justify-content: center;
  border: 1px dashed var(--fbp-secondary-main);
  border-radius: 8px;
  max-width: 480px;
  height: 280px;
  padding: 26px 32px;
  &.ImageFilledWrapper {
    border: none;
    padding: 0;
    background-color: transparent;
  }
}
.imgUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: var( --fbp-theming-font-color);
  }
}
.center {
  margin: 0 auto;
}
.right {
  margin-left: auto;
}
.left {
  margin-right: auto;
}
.fullWidth {
  margin: 0;
  width: 100%;
  max-width: 100% !important;
}
