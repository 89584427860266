@import "../../assets/scss/base/variables";

.fbpGlobalSectionTitleWr {
  box-shadow: 0px 1px 1px rgba(148, 157, 178, 0.14);
  background: var(--fbp-white-color);
  border-radius: 8px;
  padding: 0 0 28px 0;

  .fbpGlobalSectionTitleRow {
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid var(--fbp-other-outlined-border);
    padding: 16px 20px;
  }

  .fbpGlobalSectionIcon{
    display: grid;
    place-content: center;
    padding: 8px;
    border-radius: 3px;
    color: var(--fbp-background-paper);
  }

  .fbpGlobalSectionTitleHeading {
    margin: 0 0 4px 0;
  }

}