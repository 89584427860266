.fbpQuickReplaySettingWr {
  position: relative;
  height: calc(100vh - 10px);
  width: 100vw;
  overflow: hidden;
  .ShowSettingsIcon {
    background: rgba(255, 255, 255, 0.54);
    box-shadow: 0 3px 5px -1px rgba(148, 157, 178, 0.2), 0px 6px 10px rgba(148, 157, 178, 0.14),
      0px 1px 18px rgba(148, 157, 178, 0.12);
    border-radius: 30px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 10px;
    top: 77px;
    cursor: pointer;
  }
  .fbpPublicShareLeft {
    background: var(--fbp-white-color);
    max-width: 310px;
    height: calc(100vh - 52px);
    opacity: 1;
    width: 100%;
    z-index: 4;
    transition: all 0.3s ease-in-out;
    .fbpPublicSlTop {
      background: var(--fbp-secondary-main);
      padding: 7px 5px;
      color: var(--fbp-white-color) !important;
      position: relative;
      .fbpPublicHideShowIcon {
        position: absolute;
        right: 7px;
        top: 10px;
        cursor: pointer;
        svg path {
          fill: var(--fbp-white-color);
        }
      }
    }
    .fbpPublicSlLists {
      height: calc(100vh - 94px);
    }
  }
  &.fbpQuickReplayWrShow {
    .ShowSettingsIcon {
      transform: translateX(-150);
    }
  }
  &.fbpQuickReplayWrHide {
    .fbpPublicShareLeft {
      transform: translateX(-310px);
      max-width: 0;
      opacity: 0;
      z-index: -99;
    }
    .ShowSettingsIcon {
      transform: translate(0);
    }
  }
}

.fbpPublicShareWr {
  gap: 24px;
  background: #f4f5f7;
  height: 100%;
  overflow: hidden;
  padding-top: 52px;
}

.fbpPublicShareMiddle {
  overflow-x: hidden;
  margin: 24px auto;
  width: 57%;
  .fbpPublicGenerateWr {
    padding: 20px 32px 0 32px;
    .fbpPublicGenerateTop {
      grid-gap: 30px;
    }
    .fbpPublicGenerateQrTitle {
      margin: 0 0 12px 0;
    }
    .fbpPublicGenerateQrInnerRight {
      flex-grow: 1;
    }
    .fbpPublicGenerateQrLink {
      border: 1px solid var(--fbp-other-outlined-border);
      border-radius: var(--fbp-border-radius);
      font-size: var(--fbp-global-font-size-input-text);
      line-height: var(--fbp-global-line-height-input-text);
      font-weight: var(--fbp-global-font-weight-input-text);
      letter-spacing: var(--fbp-global-letter-spacing-input-text);
      color: var(--fbp-text-secondary);
      padding: 22px 12px 66px 12px;
      margin: 0 !important;
      width: 100%;
      :focus {
        outline: none;
        box-shadow: none !important;
        border-color: var(--fbp-other-outlined-border) !important;
      }
    }
  }
}

.fbpPublicShareSocialSingle {
  border: 1px solid var(--fbp-other-outlined-border);
  border-radius: 6px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  transition: 0.4s;
  background: var(--fbp-text-dark-primary);
  &:hover {
    background: var(--fbp-text-dark-secondary);
  }
}

@media screen and (max-width: 1200px) {
  .fbpPublicShareLeft {
    position: absolute;
    box-shadow: 0 3px 5px -1px rgb(148 157 178 / 20%), 0px 6px 10px rgb(148 157 178 / 14%),
      0px 1px 18px rgb(148 157 178 / 12%);
  }
  .fbpPublicShareWr {
    .fbpPublicShareMiddle {
      padding: 0;
      width: 96%;
    }
  }
}
