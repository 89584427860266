@import '../Theming/Theming';
@import '../../../assets/scss/base/variables';

.fbpSingleSectionElements {
  border: 1px dashed transparent;
  border-left: 2px solid transparent;
  position: relative;
  box-sizing: border-box;
  padding: var(--fbp-theming-field-spacing) var(--fbp-theming-inner-gap);
  //background-color: $formColor;
  & * {
    transition: all 0.3s cubic-bezier(0.47, 0.01, 0.4, 0.99);
  }
  &.fbpClassicComponentWrapper {
    padding: $fieldSpacing $innerGap;
  }
  &.fbpCardComponentWrapper {
    padding: 40px 78px 60px;
    border-radius: 8px;
    cursor: grab;
    margin-bottom: 2px;
    background-color: $formColor;
    background-image: $formImage;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      background-color: $formImageColor;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: $formImageOpacity;
      z-index: -1;
    }
    &.fbpEditAreaActive {
      border-radius: 0;
    }
  }
  &.fbpEditAreaActive {
    border-color: $fbp-secondary-main;
  }
}
:global(.fbpPhoneView) {
  .fbpSingleSectionElements {
    padding: var(--fbp-theming-field-spacing) 15px !important;
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .fbpSingleSectionElements {
    padding: var(--fbp-theming-field-spacing) 15px;
    width: 100% !important;
  }
}

.fbpResultView {
  .fbpElementOverlay {
    display: none;
  }
  .fbpBuilderSettings {
    &.fbpBuilderAddNew {
      display: none;
    }
  }
  .fbpResultView {
    .fbpBuilderDrop {
      display: none;
    }
  }
  .fbpSingleSection {
    border: 0;
  }
}
.fbpPhoneView {
  .fbpSingleSection {
    padding: 20px;

    .fbpVideo {
      &.fbpEmbededAnything {
        max-width: 100%;
      }

      &.fbpEmbededAnything {
        svg {
          max-width: 100%;
        }
      }
    }
  }
}

.fbpEditorActive {
  .fbpElement {
    & > .fbpElementOverlay {
      z-index: 9999;
    }
  }
}
.fbpElementsSettingsWrp {
  width: 100%;
  height: 30px;
  position: absolute;
  top: -30px;
  left: 0;
  .fbpElementOverlay {
    width: max-content;
    margin: 0 auto;
    position: relative;
    transition: margin 0.3s, width 0.3s, font 0.3s;
    .fbpEditorElementSettings {
      height: 30px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
      position: relative;
      box-shadow: 0 -2px 8px rgb(0 0 0 / 5%);
      background: var(--fbp-secondary-main);
      transition: margin 0.3s, width 0.3s, font 0.3s;
    }
    &::after {
      content: '';
      position: absolute;
      border-width: medium;
      top: 0;
      border-left: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-right: 15px solid transparent;
      border-right-color: var(--fbp-secondary-main);
      border-top: 30px solid transparent;
      right: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      border-width: medium;
      top: 0px;
      border-right: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 15px solid transparent;
      border-left-color: var(--fbp-secondary-main);
      border-top: 30px solid transparent;
      left: 100%;
    }
  }
}

.fbpEditAreaActive {
  .fbpBuilderEditable {
    :focus-visible {
      border-color: var(--fbp-secondary-main);
      outline: 0;
    }
  }
  .fbpEditorElementSettings {
    @mixin fbpElementSettings {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      margin: 0;
      font-size: 11px;
      color: #fff;
      width: 25px;
      -webkit-transition: margin 0.3s, width 0.3s, font 0.3s;
      -o-transition: margin 0.3s, width 0.3s, font 0.3s;
      transition: margin 0.3s, width 0.3s, font 0.3s;
    }
    .fbpEditorElementSetting {
      @include fbpElementSettings;
      .fbpDragMenu {
        cursor: move;
        svg {
          cursor: move;
        }
      }
    }
  }
}
.fbpEditorSectionSettings {
  .fbpEditorElementSetting {
    &:first-child {
      border-radius: 5px 0 0 0;
      &:before {
        @mixin beforeProps {
          content: '';
          position: absolute;
          border-width: medium;
          top: 0;
          border-left: 0 solid transparent;
          border-bottom: 0 solid transparent;
          border-right: 15px solid transparent;
          border-right-color: var(--fbp-secondary-main);
          border-top: 30px solid transparent;
        }
        right: calc(100% - 2px);
      }
    }

    &:hover {
      background-color: $fbp-dark-blue;
      border-radius: 2px;
    }
    &:last-child {
      border-radius: 0 5px 0 0;

      &:after {
        //@include beforeProps;
        left: calc(100% - 2px);
      }
    }
  }
}
