.fbpFileUploadWrapper {
  background: #ffffff;
  border: 1px dashed #006df5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
  width: 100%;
  height: 120px;
  button {
    display: none;
  }
}
