.fbpFormBuilderBodyLeftSide {
  width: 310px;
  background: var(--fbp-success-contrast);
  box-shadow: var(--fbp-elevation-2);
  transition: all 0.3s;
  position: relative;
  z-index: 11;
  height: 100%;
  button:focus {
    background-color: unset !important;
  }
  .fbpFormBuilderLsTopbar {
    display: flex;
    background: var(--fbp-secondary-main);
    height: 42px;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    span {
      display: inline-flex;
      cursor: pointer;
      svg {
        path {
          fill: var(--fbp-primary-contrast);
        }
      }
    }
  }
  .fbpTabsBottom {
    flex-direction: column-reverse;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 42px);
  }
  .fbpBuilderPagesTab {
    background: var(--fbp-action-active-black);
    height: calc(100% - 48px);
  }
}

@media (max-width: 1200px) {
  .fbpFormBuilderBodyLeftSide {
    position: absolute;
    left: 0;
    z-index: 11;
  }
}
